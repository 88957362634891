import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const IotWhenOrder: FC = () => {
  const cards = [0, 1, 2];
  const features = [0, 1, 2];
  const whenOrderPrefix = 'cases.iot.whenOrder';

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>{t(`${whenOrderPrefix}.title`)}</div>
      <div className={styles.cards}>
        {cards.map((card, index) => (
          <div key={card} className={styles.card}>
            {t(`${whenOrderPrefix}.cards.${index}`)}
          </div>
        ))}
      </div>
      <div className={styles.features}>
        {features.map((feature, index) => (
          <div key={feature} className={styles.feature}>
            <img src="/iot/dash.svg" alt="" />
            <div className={styles.feature__text}>
              <div>{t(`${whenOrderPrefix}.features.${index}.bold`)}</div>
              {t(`${whenOrderPrefix}.features.${index}.text`)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { IotWhenOrder };
