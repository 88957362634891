import React, { FC } from 'react';
import classNames from 'classnames';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';
import { Breadcrumbs } from '../../../../common/Breadcrumbs';

interface IProps {
  title: string;
}

const IotTitle: FC<IProps> = ({ title }) => {
  const prefix = usePathPrefix();

  return (
    <div
      className={classNames(styles.wrap)}
      style={{ backgroundImage: `url(${prefix}/iot/background.png)` }}
    >
      <div
        className={classNames(
          root.breadcrumbs,
          styles.title__breadcrumbs,
          styles.breadcrumbs
        )}
      >
        <Breadcrumbs title={title} />
      </div>
      <div className={styles.line}>
        <img src="/iot/line.png" alt="" />
      </div>
      <div className={styles.text}>
        <h3 className={styles.title}>{t('cases.iot.title.title')}</h3>
        <p className={styles.desc}>{t('cases.iot.title.desc')}</p>
      </div>
    </div>
  );
};

export { IotTitle };
