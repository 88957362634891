import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const IotPrinciples: FC = () => {
  const cardPrefix = 'cases.iot.principles';
  const icons = [
    '/iot/principles/shield.svg',
    '/iot/principles/page.svg',
    '/iot/principles/chat.svg',
    '/iot/principles/stack.svg',
    '/iot/principles/circle.svg',
    '/iot/principles/smartphone.svg',
    '/iot/principles/suitcase.svg',
  ];
  return (
    <div className={styles.wrap}>
      <div className={styles.title}>{t(`${cardPrefix}.title`)}</div>
      <div className={styles.desc}>{t(`${cardPrefix}.desc`)}</div>
      <div className={styles.cardContainer}>
        {icons.map((icon, index) => (
          <div key={icon} className={styles.card}>
            <div className={styles.card__group}>
              <img src={icon} alt="" />
              <div className={styles.card__title}>
                {t(`${cardPrefix}.${index}.title`)}
              </div>
            </div>
            <div className={styles.card__desc}>
              {t(`${cardPrefix}.${index}.desc`)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IotPrinciples;
