import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const IotSolutions: FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabPrefix = `cases.iot.solutions.tab_${activeTab}`;
  const buttons = [
    'cases.iot.solutions.tab_0.title',
    'cases.iot.solutions.tab_1.title',
    'cases.iot.solutions.tab_2.title',
  ];
  const icons = [
    [
      '/iot/features/zero/timer.svg',
      '/iot/features/zero/light-bulb.svg',
      '/iot/features/zero/thermometer.svg',
      '/iot/features/zero/radio-wave.svg',
      '/iot/features/zero/chart.svg',
    ],
    [
      '/iot/features/two/remote.svg',
      '/iot/features/two/off.svg',
      '/iot/features/two/check-list.svg',
      '/iot/features/two/equalizer.svg',
      '/iot/features/two/speed.svg',
    ],
    [
      '/iot/features/one/qr.svg',
      '/iot/features/one/auto-load.svg',
      '/iot/features/one/credit-card.svg',
      '/iot/features/one/history.svg',
      '/iot/features/one/recepit.svg',
    ],
  ];

  const screens = [
    '/iot/screens/0.png',
    '/iot/screens/2.png',
    '/iot/screens/1.png',
  ];
  return (
    <div className={styles.wrap}>
      <div className={styles.leftContainer}>
        <div className={styles.title}>{t('cases.iot.solutions.title')}</div>
        <div className={styles.buttonsContainer}>
          {buttons.map((buttonItem, index) => (
            <button
              key={buttonItem}
              type="button"
              className={`${styles.button} ${
                activeTab === index ? styles.button__active : ''
              }`}
              onClick={() => setActiveTab(index)}
            >
              {t(buttonItem)}
            </button>
          ))}
        </div>
        <div className={styles.rightContainerMobile}>
          <div className={styles.screen}>
            <img src={screens[activeTab]} alt="" />
          </div>
        </div>
        <div className={styles.desc}>{t(`${tabPrefix}.desc`)}</div>
        <div className={styles.featuresContainer}>
          {icons[activeTab] &&
            icons[activeTab].length > 0 &&
            icons[activeTab].map((icon, index) => (
              <div key={icon} className={styles.feature}>
                <img src={icon} alt="" />
                <div className={styles.feature__text}>
                  {t(`${tabPrefix}.features.${index}`)}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.screen}>
          <img src={screens[activeTab]} alt="" />
        </div>
      </div>
    </div>
  );
};

export default IotSolutions;
