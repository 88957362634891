import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

const IotWhyUs: FC = () => {
  const whyUsPrefix = 'cases.iot.whyUs';
  return (
    <div className={styles.wrap}>
      <div className={styles.title}>{t(`${whyUsPrefix}.title`)}</div>
      <div className={styles.desc}>{t(`${whyUsPrefix}.desc`)}</div>
      <div className={styles.text}>{t(`${whyUsPrefix}.text`)}</div>
    </div>
  );
};

export default IotWhyUs;
